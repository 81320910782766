<template>
  <div class="loginAllBox">
    <!-- 登录界面 -->
    <!-- <div class="loginBox">
      <el-form ref="loginDataBox" :model="loginData" size="small" label-width="80px">
        <el-form-item>
          <span class="title">账号密码登录</span>
        </el-form-item>
        <el-form-item label="用户名:">
          <el-input v-model="loginData.name" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input v-model="loginData.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="judgeLogin">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div> -->
    <div class="login_box" id="login_box">
      <!-- 头部标题 -->
      <div class="login_head">
        <h4>合作伙伴登录</h4>
      </div>

      <!-- tab切换页 -->
      <div class="login_tab">
        <div class="tab_item" :class="{tab_check:checkTabIndex == 0}" @click="changTabIndex(0)">
          <span class="tab_title">我是客户</span>
        </div>
        <div class="tab_item" :class="{tab_check:checkTabIndex == 1}" @click="changTabIndex(1)">
          <span class="tab_title">我是供应商</span>
        </div>
      </div>

      <!-- 登录内容 -->
      <div class="login_content">
        <ul>
          <li>
            <el-input class="input" v-model="loginData.name" placeholder="请输入用户名"></el-input>
          </li>
          <li>
            <el-input class="input" v-model="loginData.password" type="password" placeholder="请输入密码"></el-input>
          </li>
          <li>
            <el-button class="loginbtn" type="success" @click="judgeLogin">立即登录</el-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import supplierApi from "@/network/datum/supplier.js";//供应商相关接口
  import customerApi from "@/network/crm/crm.js";//客户相关接口

  export default {
    name:'customerLogin',
    data(){
      return{
        // 组件数据
        loginData:{},//用户登录
        checkTabIndex:0,//选中的tab页(0:客户  1:供应商)

        //loading框
        loading:'',//loading框
      }
    },
    methods: {
      ...mapMutations([
        'LOGIN_CUSTOMER_ID',//存储当前登录的客户id
        'LOGIN_SUPPLIER_ID',//存储当前登录的供应商id
      ]),
      /* 判断使用哪种登录方式 */
      judgeLogin() {
        if(this.checkTabIndex == 0){//客户
          this.customerLogin();
        }else if(this.checkTabIndex == 1){//供应商
          this.supplierLogin();
        }
      },

      /* 客户登录 */
      customerLogin(){
        //定义后端请求数据
        let loginData = {
          customer:this.loginData.name,
          password:this.loginData.password,
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#login_box", 4, '用户登录中,请稍候...');
        //发送请求
        customerApi.loginByCustomer(loginData).then(res=>{
          if(res.code == 200){
            //设置登录方式
            setCookie("login_type",1);
            //将客户id存入Cookie
            setCookie("partnerLoginId",res.data);
            //给出提示信息
            this.$message({
              type:'success',
              message:"登录成功!",
              duration:1500
            })
            //路由跳转
            this.$router.push({
              path: '/sell_view',
              query:{
                orderType:1,
                orderBillCode:'DOC-S-006'
              }
            })
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:3000
            })
          }
          //关闭loading框
          this.loading.close();
        })
      },

      /* 供应商登录 */
      supplierLogin(){
        //定义后端请求数据
        let loginData = {
          supplier:this.loginData.name,
          password:this.loginData.password,
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#login_box", 4, '用户登录中,请稍候...');
        //发送请求
        supplierApi.loginBySupplier(loginData).then(res=>{
          if(res.code == 200){
            //设置登录方式
            setCookie("login_type",2);
            //将供应商id存入Vuex
            setCookie("partnerLoginId",res.data);
            //给出提示信息
            this.$message({
              type:'success',
              message:"登录成功!",
              duration:1500
            })
            //路由跳转
            this.$router.push({
              path: '/purchase_view',
              query:{
                purchaseType:1,
                purchaseBillCode:'DOC-P-002'
              }
            })
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:3000
            })
          }
          //关闭loading框
          this.loading.close();
        })
      },

      /* 切换tab页 */
      changTabIndex(index){
        this.checkTabIndex = index;
      },
    },
  }
</script>


<style>
  .loginAllBox .login_content .el-input__inner{
    height: 45px;
  }
</style>
<style lang="less">
  .loginAllBox{
    height: 100vh;
    display: flex;
    justify-content: space-around;
    // border: 1px solid black;
    .login_box{
      width: 418px;
      height: 350px;
      margin-top: 60px;
      border: 1px solid #e8e8e8;
      //标题
      .login_head{
        font-size: 22px;
        font-weight: bold;
        color: #333333;
        background-color: #f5f5f5;
        text-align: center;
        padding: 20px 15px;
        // border: 1px solid black;
      }
      //登录切换页面
      .login_tab{
        display: flex;
        align-items: center;
        .tab_item{
          width: 50%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #f5f5f5;
          .tab_title{
            font-size: 14px;
            color: #555555;
            cursor: pointer;
            font-weight: bold;
          }
        }
        .tab_check{
          border-bottom: 2px solid @theme;
        }
      }
      //内容
      .login_content{
        padding: 10px 30px 30px 30px;
        ul{
          li{
            padding: 10px;
            text-align: center;
            // border: 1px solid black;
            //输入框
            .input{
              height: 45px;
            }
            //登录按钮
            .loginbtn{
              width: 200px;
              height: 45px;
              border-radius: 50px;
              // border: 1px solid black;
            }
          }
        }
      }
    }
  }
</style>
